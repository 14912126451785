import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useNotificationListing from "./useNotificationListing";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import FilterNotification from "./FilterNotification";
import Style from "./NotificationListing.module.scss"
import { Pagination } from "../../Global/Pagination";

const NotificationListing = () => {
  const {
    notificationList,
    notificationState,
    isLoading,
    isFetching,
    tableFields,
    paginationOptions,
    initialDate,
    showEditModal,
    updateNotificationsFields,
    refetch,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handlePageSize,
    handleSearch,
    handleClearClick,
    handleDateChange,
    handleCloseFilter,
    handleFilterClick,
    handleNotificationsRedirect,
    handleModal
  } = useNotificationListing();
  return (
    <div>
      <HeadingGroup title={"Notifications"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6 ${Style.search_order}`}>
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          isDateRange
          showDateRange={true}
          initialDateRange={initialDate}
          onDateChange={handleDateChange}
          handleActionClick={handleEditColumnsClick}
          searchInputProps={{ value: notificationState?.search }}
          extraFilters={
            <>
            <div className="col-auto filter">
              <button
                className={"pro-btn pro-items-center pro-btn-outline pro-h-100"}
                onClick={handleFilterClick}
              >
                Filter
              </button>
              </div>
              <div className="col-auto read-all">
              <button
                className={"pro-btn pro-items-center pro-btn-outline pro-h-100"}
                onClick={handleModal}
              >
                Read all
              </button>
              </div>
            </>
          }
        />
        {notificationList?.data?.data?.length !== 0 ? (
          <>
            <Table
              multiSelect={false}
              data={notificationList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              clear={notificationState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={notificationState?.currentPageSize}
              editable={false}
              deletable={false}
              assignable={false}
              showCheckBox={false}
            />
            {notificationList?.data?.data?.length > 0 && (
              <div className="pagination-w-100">
              <Pagination
                currentPage={notificationState?.currentPage}
                totalPageCount={Math.ceil(
                  notificationList?.data?.total_count /
                    notificationState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
              </div>
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={notificationList?.data?.fields}
              moduleId={notificationList?.data?.table_id}
              updateData={updateNotificationsFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={notificationState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterNotification refetch={refetch} />
        </OffCanvasLayout>
        <ModalLayout
          show={notificationState?.deleteModal}
          handleClose={handleModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to mark all messages as read?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleModal}
              submitText={"Yes"}
              submitAction={handleNotificationsRedirect}
              isRight={true}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default NotificationListing;
