import {
  HeadingGroup,
  ModalLayout,
  NavGroup,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./contentVerification.module.scss";
import useContentVerification from "./useContentVerification";
import { Link } from "react-router-dom";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import VerifyContent from "./VerifyContent";
import { Pagination } from "../../Global/Pagination";

const ContentVerification = () => {
  const {
    tabNavs,
    isLoading,
    isFetching,
    showEditModal,
    paginationOptions,
    contentVerificationData,
    contentVerificationState,
    hasBlockPermission,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    updateFields,
    handlePageSize,
    handlePagination,
    handleClearClick,
    handleEditColumnsClick,
    handleCloseVerifyModal,
  } = useContentVerification();
  return (
    <>
      <HeadingGroup title={"Content Verification"} className={`pro-mb-4`} />
      <div className={`${Style.main_container}`}>
        <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      <SearchFilters
        onSearchInput={handleSearch}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
        loading={isLoading}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={{ value: contentVerificationState?.search }}
        isDateRange
        showDateRange={false}
      />
      {contentVerificationData?.data?.data?.length > 0 ? (
        <>
          <Table
            multiSelect={false}
            data={contentVerificationData?.data?.data || []}
            uniqueID={"_id"}
            fields={contentVerificationData?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            clear={contentVerificationState.clearSelection}
            getRow={getRow}
            loading={isFetching}
            perpage={contentVerificationState?.currentPageSize}
            editable={contentVerificationData?.data?.permissions?.can_update}
            deletable={false}
            assignable={false}
            showCheckBox={
              contentVerificationData?.data?.permissions?.can_update
            }
          />
          {contentVerificationData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={contentVerificationState?.currentPage}
              totalPageCount={Math.ceil(
                contentVerificationData?.data?.total_count /
                  contentVerificationState.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        <EmptyData />
      )}
      <ModalLayout
        show={showEditModal}
        handleClose={handleEditColumnsClick}
        title={"Choose which columns you see"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-5">
          <OrderColumn
            refetchData={refetch}
            tableFields={contentVerificationData?.data?.fields}
            moduleId={contentVerificationData?.data?.table_id}
            updateData={updateFields}
          />
        </div>
      </ModalLayout>
      <OffCanvasLayout
        show={contentVerificationState?.verifyModal}
        className={Style.offcanvas}
        handleClose={handleCloseVerifyModal}
        title={"Verify Contents"}
        closeicon={<span className="material-symbols-outlined">close</span>}
      >
        <VerifyContent refetch={refetch} hasBlockPermission={hasBlockPermission} />
      </OffCanvasLayout>
    </>
  );
};

export default ContentVerification;
