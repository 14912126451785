import { useSelector } from "react-redux";
import { useMemo } from "react";
// components
import Dashboard from "../Components/Dashboard";
import Creation from "../Components/Campain/Creation";
import Campain from "../Components/Campain";
import Payments from "../Components/Payments";
import Approval from "../Components/Campain/Approval";
import Users from "../Components/User/Users";
import Settings from "../Components/Settings";
import Brands from "../Components/Brands";
import Interests from "../Components/CMS/Interests";
import Support from "../Components/CMS/Support";
import FAQ from "../Components/CMS/FAQ";
import Policy from "../Components/CMS/Policy";
import Terms from "../Components/CMS/Terms";
import Contact from "../Components/CMS/Contact";
import Admins from "../Components/Configure/Admins";
import ContentVerification from "../Components/Campain/ContentVerification";
import Report from "../Components/Campain/Report";
import Template from "../Components/CMS/Template";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);
  const dashBoardComponents = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];
  const campainComponents = [
    {
      component: <Campain />,
      permission: "Campain",
      path: "campain",
    },
    {
      component: <Creation />,
      permission: "Campaign",
      path: "campaign?activeTab=all",
    },
    {
      component: <Approval />,
      permission: "Approval",
      path: "approval",
    },
    {
      component: <ContentVerification />,
      permission: "Content Verification",
      path: "content-verification",
    },
    {
      component: <Report />,
      permission: "Report",
      path: "report",
    },
  ];
  const configureComponents = [
    {
      component: <Admins />,
      permission: "Admins",
      path: "configure/admins",
    },
    {
      component: <div> Roles</div>,
      permission: "Roles",
      path: "roles",
    },
  ];

  const userComponents = [
    {
      component: <Users />,
      permission: "User",
      path: "user?activeTab=all",
    },
  ];

  const supportComponents = [
    {
      component: <div>Support</div>,
      permission: "Support",
      path: "support",
    },
  ];

  const reportComponents = [
    {
      component: <div>Report</div>,
      permission: "Report",
      path: "report",
    },
  ];

  const brandComponents = [
    {
      component: <Brands />,
      permission: "Brand",
      path: "brand",
    },
  ];

  const userCategoryComponents = [
    {
      component: <div>userCategory</div>,
      permission: "User category",
      path: "user-category",
    },
  ];
  const paymentsComponents = [
    {
      component: <Payments />,
      permission: "Payments",
      path: "payments?activeTab=all",
    },
  ];
  const CMSComponents = [
    {
      component: <Interests />,
      permission: "Interest",
      path: "CMS/interests",
    },
    {
      component: <Support />,
      permission: "Support",
      path: "CMS/support",
    },
    {
      component: <FAQ />,
      permission: "FAQ",
      path: "CMS/FAQ",
    },
    {
      component: <Policy />,
      permission: "Policy",
      path: "CMS/policy",
    },
    {
      component: <Terms />,
      permission: "Terms & Conditions",
      path: "CMS/terms",
    },
    {
      component: <Contact />,
      permission: "Contact",
      path: "CMS/conatct",
    },
    {
      component: <Template/>,
      permission: "Template",
      path: "CMS/template",
    },
  ];
  const settingsComponents = [
    {
      component: <Settings />,
      permission: "Settings",
      path: "Settings",
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = globalState?.menu_permissions?.menu_permissions?.flatMap?.(
      (menu) => [
        menu?.menu_name,
        ...menu.permissions?.map((side_menu) => side_menu.sub_menu_name),
      ]
    );
    if (!menus) {
      menus = [];
    }
    menus = [...menus];
    return menus;
    //eslint-disable-next-line
  }, [globalState.menu_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }
  const allowedConfigureComponents = configureComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedDashBoardComponents = dashBoardComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  const allowedUserComponents = userComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedSupportComponents = supportComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedReportComponents = reportComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedBrandComponents = brandComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedUserCategoryComponents = userCategoryComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedCampainComponents = campainComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedPaymentsComponents = paymentsComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedCMSComponents = CMSComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  return {
    allowedCampainComponents,
    allowedConfigureComponents,
    allowedDashBoardComponents,
    allowedUserComponents,
    allowedSupportComponents,
    allowedReportComponents,
    allowedBrandComponents,
    allowedUserCategoryComponents,
    allowedPaymentsComponents,
    allowedCMSComponents,
    allowedSettingsComponents: settingsComponents,
  };
};

export default useRoutes;
