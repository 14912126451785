import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
  Navigate,
} from "react-router-dom";
import Index from "../Pages";
import AuthModule from "../Components/Auth";
import Login from "../Components/Auth/Login";
import ForgotPassword from "../Components/Auth/ForgotPassword";
import ResetPassword from "../Components/Auth/ResetPassword";
import Home from "../Components/Home";
import HomePage from "../Components/Home/HomePage";
import Dashboard from "../Components/Dashboard";
import DashboardHome from "../Components/Dashboard/DashboardHome";
import Campain from "../Components/Campain";
import Creation from "../Components/Campain/Creation";
import Approval from "../Components/Campain/Approval";
import Settings from "../Components/Settings";
import Appearance from "../Components/Settings/Appearance";
import useTheme from "../Components/Settings/Appearance/useTheme";
import useDrawerTheme from "../Components/Settings/Appearance/useDrawerTheme";
import Brands from "../Components/Brands";
import BrandList from "../Components/Brands/BrandList";
import Payments from "../Components/Payments";
import Payout from "../Components/Payments/Payout";
import CampaignDetailsLayout from "../Components/Campain/CampainDetailsLayout";
import CampaignDetails from "../Components/Campain/CampaignDetails";
import User from "../Components/User";
import Users from "../Components/User/Users";
import UserDetailsLayout from "../Components/User/UserDetailsLayout";
import UserDetails from "../Components/User/UserDetails";
import CMS from "../Components/CMS";
import Interests from "../Components/CMS/Interests";
import Support from "../Components/CMS/Support";
import FAQ from "../Components/CMS/FAQ";
import Policy from "../Components/CMS/Policy";
import Terms from "../Components/CMS/Terms";
import Contact from "../Components/CMS/Contact";
import Configure from "../Components/Configure";
import Admins from "../Components/Configure/Admins";
import Roles from "../Components/Configure/Roles";
import ManageRole from "../Components/Configure/Roles/ManageRole";
import Notification from "../Components/Notification";
import NotificationListing from "../Components/Notification/NotificationListing";
import BrandsDetailslayout from "../Components/Brands/BrandsDetailsLayout";
import BrandDetails from "../Components/Brands/BrandList/BrandsDetails";
import InterestChange from "../Components/User/InterestChange";
import ContentVerification from "../Components/Campain/ContentVerification";
import Adminsactivity from "../Components/Configure/Adminsactivity";
import WalletTransaction from "../Components/User/WalletTransaction";
import Report from "../Components/Campain/Report";
import Template from "../Components/CMS/Template";

const Routes = () => {
  useTheme("light");
  useDrawerTheme("expanded");

  return (
    <div>
      <BrowserRouter>
        <BrowserRoutes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/login" element={<AuthModule />}>
            <Route path="" element={<Login />}></Route>
            <Route path="forgot-password" element={<ForgotPassword />}></Route>
            <Route path="reset-password" element={<ResetPassword />}></Route>
          </Route>
          <Route path="/home" element={<Home />}>
            <Route path="" element={<HomePage />}></Route>
          </Route>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="" element={<DashboardHome />}></Route>
          </Route>
          <Route path="notifications" element={<Notification />}>
            <Route path="" element={<NotificationListing />} />
          </Route>
          <Route path="/campaign" element={<Campain />}>
            <Route path="" element={<Creation />}></Route>
            <Route path="approval" element={<Approval />}></Route>
            <Route
              path="content-verification"
              element={<ContentVerification />}
            />
            <Route path="report" element={<Report/>}></Route>
          </Route>
          <Route path="campaign-details" element={<CampaignDetailsLayout />}>
            <Route path=":campaignID" element={<CampaignDetails />}></Route>
            {/* <Route path=":campaignID/:campaignType" element={<CampaignDetails />} /> */}
          </Route>
          <Route path="/settings" element={<Settings />}>
            <Route path="" element={<Appearance />}></Route>
          </Route>
          <Route path="/brand" element={<Brands />}>
            <Route path="" element={<BrandList />}></Route>
          </Route>
          <Route path="brand-details" element={<BrandsDetailslayout />}>
            <Route path=":brandID" element={<BrandDetails />}></Route>
          </Route>
          <Route path="/payments" element={<Payments />}>
            <Route path="" element={<Payout />}></Route>
          </Route>
          <Route path="/CMS" element={<CMS />}>
            <Route path="interests" element={<Interests />}></Route>
            <Route path="support" element={<Support />}></Route>
            <Route path="FAQ" element={<FAQ />}></Route>
            <Route path="policy" element={<Policy />}></Route>
            <Route path="terms" element={<Terms />}></Route>
            <Route path="contact" element={<Contact />}></Route>
            <Route path="template" element={<Template/>}></Route>
          </Route>
          <Route path="/configure" element={<Configure />}>
            <Route path="admins" element={<Admins />}></Route>
            <Route path="roles" element={<Roles />}></Route>
            <Route path="admin-activity" element={<Adminsactivity />}></Route>
            <Route path="roles/role-permissions" element={<ManageRole />} />
          </Route>
          <Route path="/user" element={<User />}>
            <Route path="" element={<Users />}></Route>
            <Route path="interest-change" element={<InterestChange />}></Route>
            <Route path="wallet-transactions" element={<WalletTransaction />}></Route>
          </Route>
          <Route path="user-details" element={<UserDetailsLayout />}>
            <Route path=":userID" element={<UserDetails />}></Route>
          </Route>
          <Route path={"/*"} element={<Navigate to={"/"} />} />
        </BrowserRoutes>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
