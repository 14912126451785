import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../Store/Queries/Dashboard";
import { updateConfig } from "../../../Store/Slices/Dashboard/dashboardSlice";
import { updateConfig as globalUpdateConfig } from "../../../Store/Slices/Global";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PiTiktokLogo } from "react-icons/pi";
import { BiLogoInstagram } from "react-icons/bi";
import { SlSocialFacebook } from "react-icons/sl";
import { MdLogout } from "react-icons/md";
import { SlSocialYoutube } from "react-icons/sl";
import { MdPendingActions } from "react-icons/md";
import { getFormatedDate } from "../../../utils/Table";

const useDashboardHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const { tableFields } = useSelector((state) => state.global);
  const [listingType, setListingType] = useState();
  const [initialDate, setInitialDate] = useState({
    startDate: dashboardState.startDate
      ? dashboardState.startDate
      : null,
    endDate: dashboardState.endDate
      ? dashboardState.endDate
      : null,
    key: "selection",
  });
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({
    start_date: dashboardState?.startDate
      ? getFormatedDate(dashboardState?.startDate)
      : "",
    end_date: dashboardState?.endDate
      ? getFormatedDate(dashboardState?.endDate)
      : "",
  });

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {}) ?? "");
    }
    //eslint-disable-next-line
  }, [isSuccess]);
  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = dashboardData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [dashboardData]);
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleClick = (item) => {
    switch (item) {
      case "total_campaigns":
        navigate("/campaign?activeTab=all");
        break;
      case "total_influencers":
        navigate("/user?activeTab=all");
        break;
      case "total_brands":
        navigate("/brand");
        break;
      case "total_supports":
        navigate("/CMS/support");
        break;
      default:
        break;
    }
  };
  const handleDateChange = (date) => {
    dispatch(
      updateConfig((state) => {
        state.startDate = new Date(date.startDate);
        state.endDate = new Date(date.endDate);
      })
    );
    setInitialDate({
      startDate: new Date(date.startDate),
      endDate: new Date(date.endDate),
      key: "selection",
    });
  };
  let dashboardItems = {
    total_campaigns: {
      label: "total_campaigns",
      title: "Total Campaigns",
      icon: "workspace_premium",
      handleClick,
    },
    total_influencers: {
      label: "total_influencers",
      title: "Total Influencers",
      icon: "group",
      handleClick,
    },
    total_brands: {
      label: "total_brands",
      title: "Total Brands",
      icon: "category",
      handleClick,
    },
    total_supports: {
      label: "total_supports",
      title: "Total Supports",
      icon: "speaker_notes",
      handleClick,
    },
    user_applied_campaigns: {
      label: "user_applied_campaigns",
      title: "User Applied Campaigns",
      icon: "workspace_premium",
    },
    no_social_media_user_count: {
      label: "no_social_media_user_count",
      title: "No Social Media User Count",
      icon: "group",
    },
    pending_connection_count: {
      label: "pending_connection_count",
      title: "Pending Connection Count",
      icon: "group",
    },
    instagram_connection_count: {
      label: "instagram_connection_count",
      title: "Instagram Connection Count",
      icon: "reactIcon",
      reactIcon: <BiLogoInstagram />,
    },
    tiktok_connection_count: {
      label: "tiktok_connection_count",
      title: "TikTok Connection Count",
      icon: "reactIcon",
      reactIcon: <PiTiktokLogo />,
    },
    facebook_connection_count: {
      label: "facebook_connection_count",
      title: "Facebook Connection Count",
      icon: "reactIcon",
      reactIcon: <SlSocialFacebook />,
    },
    youtube_connection_count: {
      label: "youtube_connection_count",
      title: "YouTube Connection Count",
      icon: "reactIcon",
      reactIcon: <SlSocialYoutube />,
    },
    withdrawal_request_count: {
      label: "withdrawal_request_count",
      title: "Withdrawal Request Count",
      icon: "reactIcon",
      reactIcon: <MdLogout />,
    },
    total_payment_completed: {
      label: "total_payment_completed",
      title: "Total Payment Completed",
      icon: "paid",
    },
    pending_request_count: {
      label: "pending_request_count",
      title: "Pending Request Count",
      icon: "reactIcon",
      reactIcon: <MdPendingActions />,
    },
    pending_content_verification_count: {
      label: "pending_content_verification_count",
      title: "Pending Content Verification Count",
      icon: "verified_user",
    },
    pending_users_payment_to_wallet_count: {
      label: "pending_users_payment_to_wallet_count",
      title: "Pending Users' Payment to Wallet Count",
      icon: "work_history",
    },

    // pending_approval: {
    //   label: "pending_approval",
    //   title: "Pending Approval",
    //   icon: "approval_delegation",
    //   handleClick,
    // },
  };
  const handleSort = (label) => {
    if (dashboardState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = dashboardState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    dashboardData,
    listingType,
    dashboardItems,
    isFetching,
    isSuccess,
    initialDate,
    tableFields,
    dashboardState,
    getRow,
    handleDateChange,
    handleSort,
  };
};

export default useDashboardHome;
