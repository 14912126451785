import {
  HeadingGroup,
  ModalLayout,
  NavGroup,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { Link } from "react-router-dom";
import Style from "./users.module.scss";
import useUsers from "./useUsers";
import AddUser from "./AddUser";
import { Pagination } from "../../Global/Pagination";
import FilterUser from "./FilterUser";

const Users = () => {
  const {
    tabNavs,
    usersList,
    isLoading,
    usersState,
    isFetching,
    tableFields,
    initialDate,
    showEditModal,
    paginationOptions,
    activeTab,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleCloseModal,
    handlePagination,
    handleDateChange,
    handleEditAction,
    handleExportTable,
    handleClearClick,
    handleCreateClick,
    updateUsersFields,
    handleEditColumnsClick,
    handleCloseFilter,
    handleFilterClick,
  } = useUsers();
  return (
    <div className="col-auto pro-pt-3 pro-pb-6">
      <HeadingGroup
        title={"Users"}
        className={`pro-mb-4`}
        buttonTitle={usersList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className={`${Style.main_container}`}>
        <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      <div className={`${activeTab!=="google" && Style.search_order}`}>
      <SearchFilters
        onSearchInput={handleSearch}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
        loading={isLoading}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={{ value: usersState?.search }}
        isDateRange
        showDateRange={true}
        initialDateRange={initialDate}
        onDateChange={handleDateChange}
        extraFilters={activeTab!=="google" &&
          <>
          <div className="col-auto filter">
            <button
              className={"pro-btn pro-items-center pro-btn-outline pro-h-100"}
              onClick={handleFilterClick}
            >
              Filter
            </button>
            </div>
          </>
        }
        filterChildren={
          <div className="col-auto pro-ms-auto">
            <button
              className={"pro-btn pro-items-center pro-btn-outline "}
              onClick={handleExportTable}
            >
              <span className="material-symbols-outlined x4">
                outgoing_mail
              </span>
              <span>Export Table</span>
            </button>
          </div>
        }
      />
      </div>
      {usersList?.data?.data?.length > 0 ? (
        <>
          <Table
            multiSelect={false}
            data={usersList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            clear={usersState.clearSelection}
            getRow={getRow}
            loading={isFetching}
            perpage={usersState?.currentPageSize}
            editable={usersList?.data?.permissions?.can_update}
            deletable={false}
            assignable={false}
            showCheckBox={usersList?.data?.permissions?.can_update}
            handleEdit={handleEditAction}
          />
          {usersList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={usersState?.currentPage}
              totalPageCount={Math.ceil(
                usersList?.data?.total_count / usersState.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        <EmptyData />
      )}
      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={usersList?.data?.fields}
            moduleId={usersList?.data?.table_id}
            updateData={updateUsersFields}
          />
        </div>
      </ModalLayout>
      <OffCanvasLayout
        show={usersState?.createModal}
        handleClose={handleCloseModal}
        title={usersState?.isEdit ? "Update User" : "Create User"}
        closeicon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        className={Style.offcanvas}
      >
        <AddUser refetch={refetch} />
      </OffCanvasLayout>
      <OffCanvasLayout
          show={usersState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterUser refetch={refetch} activeTab={activeTab}/>
        </OffCanvasLayout>
    </div>
  );
};

export default Users;
