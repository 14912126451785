import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
//   import Reject from "./Reject";
import { Pagination } from "../../Global/Pagination";
import useReport from "./useReport";
import FilterReport from "./FilterReport";

const Report = () => {
  const {
    getRow,
    refetch,
    handleSort,
    updateFields,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleClearClick,
    handleEditColumnsClick,
    handleDateChange,
    handleExportTable,
    handleFilterClick,
    handleCloseFilter,
    initialDate,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    reportList,
    reportState,
  } = useReport();
  return (
    <div>
      <HeadingGroup title={"Report"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6 `}>
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: reportState?.search }}
          isDateRange
          showDateRange={true}
          initialDateRange={initialDate}
          onDateChange={handleDateChange}
          extraFilters={
            <>
            <div className="col-auto filter">
              <button
                className={"pro-btn pro-items-center pro-btn-outline pro-h-100"}
                onClick={handleFilterClick}
              >
                Filter
              </button>
              </div>
            </>
          }
          filterChildren={
            reportList?.data?.permissions?.can_export && (
              <div className="col-auto pro-ms-auto">
                <button
                  className={"pro-btn pro-items-center pro-btn-outline "}
                  onClick={handleExportTable}
                >
                  <span className="material-symbols-outlined x4">
                    outgoing_mail
                  </span>
                  <span>Export Table</span>
                </button>
              </div>
            )
          }
        />
        {reportList?.data?.data?.length > 0 ? (
          <>
            <Table
              multiSelect={false}
              data={reportList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              clear={reportState?.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={reportState?.currentPageSize}
              editable={false}
              deletable={false}
              assignable={false}
              showCheckBox={reportList?.data?.permissions?.can_update}
            />
            {reportList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={reportState?.currentPage}
                totalPageCount={Math.ceil(
                  reportList?.data?.total_count / reportState.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={reportList?.data?.fields}
              moduleId={reportList?.data?.table_id}
              updateData={updateFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={reportState?.createFilterModal}
          handleClose={handleCloseFilter}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <FilterReport refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default Report;
