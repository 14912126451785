import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBrandList from "./useBrandList";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { ProfileCard } from "../../Global/ProfileCard";
import AddBrand from "./AddBrand";
import { Pagination } from "../../Global/Pagination";

const BrandList = () => {
  const {
    brandList,
    brandState,
    isLoading,
    tableFields,
    isFetching,
    paginationOptions,
    showEditModal,
    handleEditAction,
    closeLogoModal,
    handleSearch,
    handleCreateClick,
    handleSort,
    handleClearClick,
    getRow,
    updateBrandFields,
    handlePagination,
    handlePageSize,
    handleEditColumnsClick,
    refetch,
  } = useBrandList();
  return (
    <div>
      <HeadingGroup
        title={"Brands"}
        className={`pro-mb-4`}
        buttonTitle={brandList?.data?.permissions?.can_create && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: brandState?.search }}
        />
        {brandList?.data?.data?.length > 0 ? (
          <>
            <Table
              multiSelect={false}
              data={brandList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleEdit={handleEditAction}
              handleSort={handleSort}
              clear={brandState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={brandState?.currentPageSize}
              editable={brandList?.data?.permissions?.can_update}
              deletable={false}
              assignable={false}
              showCheckBox={brandList?.data?.permissions?.can_update}
            />
            {brandList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={brandState?.currentPage}
                totalPageCount={Math.ceil(
                  brandList?.data?.total_count / brandState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout show={brandState?.logoModal} handleClose={closeLogoModal}>
          <ProfileCard data={brandState?.logo} handleClose={closeLogoModal} />
        </ModalLayout>
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={brandList?.data?.fields}
              moduleId={brandList?.data?.table_id}
              updateData={updateBrandFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={brandState?.addModal}
          handleClose={handleCreateClick}
          title={brandState?.isEdit ? "Edit Brand" : "Add Brand"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddBrand refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default BrandList;
