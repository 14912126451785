import {
  ModalLayout,
  NavGroup,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import useApproval from "./useApproval";
import { FaSort } from "react-icons/fa";
import { Link } from "react-router-dom";
import Style from "./approval.module.scss";
import CardHero from "./CardHero";
import Reject from "./Reject";
import { Pagination } from "../../Global/Pagination";

const Approval = () => {
  const {
    tabNavs,
    isLoading,
    isFetching,
    initialDate,
    tableFields,
    showEditModal,
    paginationOptions,
    campaignApprovalList,
    campaignApprovalState,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleDateChange,
    handleClearClick,
    handlePagination,
    handleCloseRejectModal,
    handleEditColumnsClick,
    updateCampaignApprovalFields,
  } = useApproval();
  return (
    <div className="col-auto pro-pt-3 pro-pb-6">
      <CardHero />
      <div className={`${Style.main_container}`}>
        <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      <SearchFilters
        onSearchInput={handleSearch}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
        loading={isLoading}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={{ value: campaignApprovalState?.search }}
        isDateRange
        showDateRange={true}
        initialDateRange={initialDate}
        onDateChange={handleDateChange}
      />
      {campaignApprovalList?.data?.data?.length > 0 ? (
        <>
          <Table
            multiSelect={false}
            data={campaignApprovalList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            clear={campaignApprovalState.clearSelection}
            getRow={getRow}
            loading={isFetching}
            perpage={campaignApprovalState?.currentPageSize}
            editable={campaignApprovalList?.data?.permissions?.can_update}
            deletable={false}
            assignable={false}
            showCheckBox={campaignApprovalList?.data?.permissions?.can_update}
          />
          {campaignApprovalList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={campaignApprovalState?.currentPage}
              totalPageCount={Math.ceil(
                campaignApprovalList?.data?.total_count /
                  campaignApprovalState.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </>
      ) : (
        <EmptyData />
      )}
      <ModalLayout
        show={campaignApprovalState?.rejectModal}
        handleClose={handleCloseRejectModal}
        title={"Reject Reason"}
      >
        <Reject refetch={refetch} />
      </ModalLayout>
      <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={campaignApprovalList?.data?.fields}
            moduleId={campaignApprovalList?.data?.table_id}
            updateData={updateCampaignApprovalFields}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

export default Approval;
