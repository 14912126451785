import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./Store";
import { dashboard } from "./Store/Queries/Dashboard";
import { campaign } from "./Store/Queries/Campaign";
import { brand } from "./Store/Queries/Brand";
import { payments } from "./Store/Queries/Payments";
import { users } from "./Store/Queries/Users";
import { interests } from "./Store/Queries/Interests";
import { FAQ } from "./Store/Queries/FAQ";
import { support } from "./Store/Queries/Support";
import { admins } from "./Store/Queries/Admins";
import { roles } from "./Store/Queries/Roles";
import { terms } from "./Store/Queries/Terms";
import { policy } from "./Store/Queries/Policy";
import { notifications } from "./Store/Queries/Notifications";
import { adminactivity } from "./Store/Queries/Adminactivity";
import { template } from "./Store/Queries/Template";
const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      FAQ.middleware,
      brand.middleware,
      users.middleware,
      roles.middleware,
      terms.middleware,
      policy.middleware,
      admins.middleware,
      support.middleware,
      payments.middleware,
      campaign.middleware,
      dashboard.middleware,
      interests.middleware,
      notifications.middleware,
      adminactivity.middleware,
      template.middleware,
    ),
});
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals();
