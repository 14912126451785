import {
  DataContainer,
  ModalLayout,
  NavGroup,
  OffCanvasLayout,
  SearchFilters,
  SimpleReport,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./campaignDetails.module.scss";
import useCampaignDetails from "./useCampaignDetails";
import EmptyData from "../../Global/EmptyData";
import { Link } from "react-router-dom";
import { FaSort } from "react-icons/fa";
import Verify from "./Verify";
import { Pagination } from "../../Global/Pagination";
import Block from "./Block";

const CampaignDetails = () => {
  const {
    tabNavs,
    timeLeft,
    tableFields,
    headerDetails,
    campaignData,
    tableFetching,
    reportFetching,
    paginationOptions,
    campaignCreateState,
    campaignDetailsTableList,
    isLoading,
    handleClearClick,
    handleSearch,
    getRow,
    handleSort,
    listRefetch,
    handlePageSize,
    handleExportTable,
    handlePagination,
    handleVerifyModalClose,
    handleBlockModal,
  } = useCampaignDetails();

  return (
    <>
      <div className="pro-m-4 pro-mx-5">
        <DataContainer propStyle={{ root: Style.container_root }}>
          {reportFetching || timeLeft === "" ? (
            <div className="row pro-w-100">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <div className="col " key={index}>
                    <div className="shimmer pro-d-block pro-p-2 pro-mb-3"></div>
                    <div className="shimmer pro-d-block pro-p-3"></div>
                  </div>
                ))}
            </div>
          ) : (
            <SimpleReport
              data={headerDetails}
              propStyle={{
                item: `${Style.report_root} ${
                  campaignData?.data?.is_live && Style.big
                }`,
                textInner : ""
              }}
            />
          )}
        </DataContainer>
        <div className={`${Style.main_container}`}>
          <div className="row pro-items-center">
            <div className="col">
              <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
            </div>
            {/* {campaignDetailsTableList?.data?.permissions?.can_export && (
              <div className="col-auto">
                <button
                  className={"pro-btn pro-items-center pro-btn-outline  "}
                  onClick={handleExportTable}
                >
                  <span className="material-symbols-outlined x4">
                    outgoing_mail
                  </span>
                  <span>Export Table</span>
                </button>
              </div>
            )} */}
          </div>
        </div>
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={false}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: campaignCreateState?.detailsPage?.search }}
          showDateRange={false}
          filterChildren={
            <div className="col-auto pro-ms-auto">
              <button
                className={"pro-btn pro-items-center pro-btn-outline "}
                onClick={handleExportTable}
              >
                <span className="material-symbols-outlined x4">
                  outgoing_mail
                </span>
                <span>Export Table</span>
              </button>
            </div>
          }
        />
        {campaignDetailsTableList?.data?.data?.length > 0 ? (
          <div>
            <Table
              multiSelect={false}
              data={campaignDetailsTableList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              getRow={getRow}
              loading={tableFetching}
              perpage={campaignCreateState?.detailsPage.currentPageSize}
              editable={false}
              deletable={false}
              assignable={false}
              handleSort={handleSort}
              SortIcon={<FaSort />}
              extraClasssName={`pro-pb-6`}
            />
            {campaignDetailsTableList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={campaignCreateState?.detailsPage?.currentPage}
                totalPageCount={Math.ceil(
                  campaignDetailsTableList?.data?.total_count /
                    campaignCreateState?.detailsPage?.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </div>
        ) : (
          <EmptyData />
        )}

        <OffCanvasLayout
          show={campaignCreateState?.verifyModal}
          handleClose={handleVerifyModalClose}
          title={"Verify Links"}
          closeicon={<span className="material-symbols-outlined">close</span>}
          className={Style.offcanvas}
        >
          <Verify listRefetch={listRefetch} />
        </OffCanvasLayout>
        <ModalLayout
          show={campaignCreateState?.detailsPage.blockModal}
          handleClose={handleBlockModal}
          title={"Block Reason"}
        >
          <Block refetch={listRefetch} />
        </ModalLayout>
      </div>
    </>
  );
};

export default CampaignDetails;
