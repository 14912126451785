import React from "react";
import useAdminsactivity from "./useAdminactivity";
import {
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { Pagination } from "../../Global/Pagination";

const Adminsactivity = () => {
  const {
    isLoading,
    isFetching,
    adminactivtiyList,
    tableFields,
    adminsactivityState,
    showEditModal,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handlePageSize,
    handleClearClick,
    handlePagination,
    updateAdminactivityFields,
    handleEditColumnsClick,
  } = useAdminsactivity();

  return (
    <div>
      <HeadingGroup
        title={"Admin Activity"}
        className={`pro-mb-4`}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          handleActionClick={handleEditColumnsClick}
          searchInputProps={{ value: adminsactivityState?.search }}
        />
        {adminactivtiyList?.data?.data?.length > 0 ? (
          <>
            <Table
              multiSelect={false}
              data={adminactivtiyList?.data?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              clear={adminsactivityState.clearSelection}
              getRow={getRow}
              loading={isFetching}
              perpage={adminsactivityState?.currentPageSize}
              editable={false}
              deletable={false}
              assignable={false}
              showCheckBox={false}
            />
            {adminactivtiyList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={adminsactivityState?.currentPage}
                totalPageCount={Math.ceil(
                    adminactivtiyList?.data?.total_count / adminsactivityState.currentPageSize
                )}
                options={paginationOptions}
                onPageChange={handlePagination}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={adminactivtiyList?.data?.fields}
              moduleId={adminactivtiyList?.data?.table_id}
              updateData={updateAdminactivityFields}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Adminsactivity;
